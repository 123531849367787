@import 'styles';

.footer-component {
  padding: 0px 16px;

  > .container {
    @include container;

    @include for-tablet-landscape-up {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    border-top: 1px solid $gray;
    padding: 16px 0 32px;

    > .info {
      display: flex;

      > .icon {
        color: $footer-lock-svg-color;
        height: 24px;
        margin-top: 2px;
        width: 24px;
      }

      > .wrapper {
        flex: 1;
        margin-left: 8px;

        > .text {
          display: inline;
          font-size: 14px;
          font-weight: 500;

          @include for-tablet-landscape-up {
            display: block;
          }
        }
      }
    }

    > .badges {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 16px;
      padding-inline: 10px;

      @include for-tablet-portrait-up {
        align-items: center;
        flex-direction: row;
      }

      @include for-tablet-landscape-up {
        margin-right: 67px;
        margin-top: 0;
      }

      > .privacy-policy-url {
        font-size: 14px;
        font-weight: 500;
        text-decoration: underline;

        @include for-tablet-portrait-up {
          text-align: right;
        }
      }

      > .link {
        > .icon {
          height: 32px;
        }
      }
    }
  }
}
