$action-button-background: #efefef;
$black: #000;
$gray: #20222e;
$green: #7fb70e;
$light-gray: #efefef;
$light-green: #d1e8c9;
$silver: #bdbdbd;
$light-red: #ffe9e8;
$orange: #f17a24;
$overlay-shadow: rgba(41, 41, 41, 0.7019607843137254);
$red: #fb2626;
$sunset-orange: #ff5252;
$svg-background: #f2f2f2;
$text-darker: #1e1e1e;
$text-dark: #5e5e5e;
$text-light: #676767;
$white: #fff;
$alto: #d9d9d9;
$wild-sand: #f5f5f5;
$mine-shaft: #2e2e2e;
$mine-shaft-darker: #292929;
$text: $mine-shaft-darker;
$boulder: #747474;
$mercury: #e1e1e1;
$silver-chalice: #acacac;
$tundora: #414141;
$mine-shaft: #333333;
$saphire: #00174f;
$dusty-gray: #9b9b9b;
$gallery: #eaeaea;
